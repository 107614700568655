<template>
  <div>
    <TTableAdvance
      :items="funds"
      :fields="fields"
      store="accounting.funds"
      @click-clear-filter="clearFilter"
      resource="/accounting/account/funds"
      enterable
      creatable
      @click-create="showModalCreate = true"
    >
      <template #account_id="{ item }">
        <td>
          <SMessageAccount :value="item.account_id" />
        </td>
      </template>
      <template #organization_id="{ item }">
        <td>
          <SMessageOrganization :id="item.organization_id" />
        </td>
      </template>
      <template #currency="{ item }">
        <td>
          <SMessageCurrency :id="item.currency_id" />
        </td>
      </template>
      <template #balance="{ item }">
        <td>
          <TMessageMoney :amount="item.balance" :currency="item.currency_id" />
        </td>
      </template>
      <template #id-filter>
        <TInputText :value.sync="filter.id" @update:value="filterChange" />
      </template>
      <template #name-filter>
        <TInputText :value.sync="filter.name" @update:value="filterChange" />
      </template>
      <template #currency-filter>
        <SSelectCurrency
          :value.sync="filter.currency_id"
          @change="filterChange"
        />
      </template>
      <template #organization_id-filter>
        <SSelectOrganization
          :value.sync="filter.organization_id"
          @change="filterChange"
        />
      </template>
    </TTableAdvance>
    <FundModal :show.sync="showModalCreate" />
  </div>
</template>

<script>
import FundModal from "./FundModal.vue";
import { mapGetters } from "vuex";
export default {
  components: { FundModal },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px" },
        {
          key: "balance",
          label: "Balance",
          _style: "min-width: 300px; width: 300px",
          sorter: true,
        },
        {
          key: "account_id",
          label: "Accounting account",
          _style: "min-width: 300px; width: 300px",
          _classes: "",
        },
        {
          key: "currency",
          label: "Currency",
          _style: "min-width: 200px; width: 200px",
          _classes: "text-wrap",
        },
        {
          key: "organization_id",
          label: "Organization",
          _style: "min-width: 200px; width: 300px",
          _classes: "",
        },
      ],
      showModalCreate: false,
      filter: {},
      dateRange: {
        start: null,
        end: null,
      },
    };
  },
  created() {
    this.$store.dispatch("accounting.funds.apply-query", {});
  },
  computed: {
    ...mapGetters({
      funds: "accounting.funds.list",
    }),
  },
  methods: {
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterFields = this.lodash.pickBy(filter);
      this.$store.dispatch("accounting.funds.apply-query", filterFields);
    },
    setCreatedAtFilter({ start, end }) {
      this.filter.between = `updated_at:${start}|${end}`;
      this.filterChange();
    },
    clearFilter() {
      this.filter = {};
      this.dateRange = {
        start: null,
        end: null,
      };
      this.filterChange();
    },
  },
};
</script>
